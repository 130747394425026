<template>
    <div class="full-screen-div">
        <div style="width: calc(100% - 2px); height: 50px; border: 1px solid #c1c1c1;background: #FFFFFF;">
            <div style="width: 130px; height: 35px; margin-top: 7.5px; margin-left: 24px;">
                <img style="width: 100%; height: 100%;" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/bai_log.png">
            </div>
        </div>
        <div style="width: calc(100% - 2px); height: 50px; border: 1px solid #c1c1c1; border-top: none; display: flex; align-items: center;background: #FFFFFF;">
            <div style="width: 130px; line-height: 50px; margin-left: 28px;">
                支付
            </div>
            <div v-if="inPayment == 0" style="width: 430px; height: 50px;  margin: 0 auto;position: absolute;margin-left: calc(50% - 215px);">
                <div style="width: 430px;height: 28px;margin-top: 11px;background: #FFF3E0;">
                    <div style="width: 16px;height: 14px;float: left;margin-top: 4px;margin-left: 5px;">
                        <img style="width: 100%;height: 100%;" src="@/assets/imgs/other/jg.png">
                    </div>
                    <div style="width:calc(100% - 26px);line-height: 23px;float: left;padding-left: 5px;">
                        <span style="font-size: 12px;">安全提示：支付前请检查您周围的支付环境，避免支付信息泄露</span>
                    </div>
                </div>
            </div>
        </div>
        <!--支付进行中-->
        <div v-if="inPayment == 0" style="width: 100%;height: 800px;background: #F1F0F4;">
            <!--支付订单显示-->
            <div style="width: 80%;height:176px;margin: 0 auto;background: #FFFFFF;margin-top: 10px;">
                <div style="width: 100%;height: auto;display: flex;border-bottom: 1px dashed #EBECEC;">
                    <div style="width: 97%;height: 48px;margin: 0 auto;">
                        <div style="width: 120px;line-height: 48px;font-size: 14px;padding-left: 10px;">待支付订单</div>
                    </div>
                    <div style="calc(100% - 120px);height: 48px;margin: 0 auto;">
                        <div style="width: 220px;line-height: 48px;font-size: 14px;text-align: right;padding-right: 10px;">
                            <span style="font-weight: bold;">应付：</span>
                            <span style="font-weight: bold;color: #FF8A00;">￥{{payParams.payAmt}}</span>
                        </div>
                    </div>
                </div>
                <div style="width:calc(100% - 20px); height: 86px;margin: 0 auto;overflow-x: auto;padding-top: 20px;">
                    <table>
                        <tr style="background: #F5F5F6">
                            <td v-if="orderNo != ''">订单号</td>
                            <td>产品</td>
                            <td>数量</td>
                            <td>下单时间</td>
                            <td style="text-align: right;padding-right: 10px;">总额</td>
                        </tr>
                        <tr>
                            <td v-if="orderNo != ''">{{orderNo}}</td>
                            <td>{{productName.note}}</td>
                            <td>1</td>
                            <td>{{productName.time}}</td>
                            <td style="text-align: right;padding-right: 10px;">{{payParams.payAmt}}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <!--支付方式显示-->
            <div v-if="paramsSatus == 1" style="width: 80%;height:auto;max-height:315px;margin: 0 auto;background: #FFFFFF;margin-top: 10px;">
                <div style="width: 100%;height: auto;display: flex;border-bottom: 1px dashed #EBECEC;">
                    <div style="width: 100%;height: 48px;margin: 0 auto;">
                        <div style="width: 120px;line-height: 48px;font-size: 14px;padding-left: 10px;">请选择支付方式</div>
                    </div>
                </div>
                <div style="width:calc(100% - 20px); height: auto;max-height:285px;margin: 0 auto;overflow-x: auto;">
                    <template>
                        <el-tabs v-model="activeName" @tab-click="handleClick">
                            <el-tab-pane label="扫码支付" name="scanCode" >
                                <div style="text-align: center;">
                                    <div style="width: 160px;height: 160px;border: 1px solid #E4E7ED;background: #ECF4FB;margin: 0 auto;">
                                        <vue-qr
                                            :text="qrImgUrl + '?sign=' + sign"
                                            :size="160"
                                            :logoScale="0.2">
                                        </vue-qr>
                                    </div>
                                    <div style="width: 160px;line-height: 35px;display: flex;margin: 0 auto;">
                                        <div style="width: 20px;height: 20px;margin-right: 3px;">
                                            <img style="width: 100%;height: 100%;" src="@/assets/imgs/other/weixin.png">
                                        </div>
                                        <div style="width: 20px;height: 20px;margin-right: 3px;">
                                            <img style="width: 100%;height: 100%;" src="@/assets/imgs/other/alipay.png">
                                        </div>
                                        <div style="font-size: 12px;">微信/支付宝扫码支付</div>
                                    </div>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="对公支付" name="corporate" v-if="user.userType == 2">
                                <div style="width: 100%;height: 100%;float:left;">
                                    <div v-if="corpCardInfo != null" :style="corporateRadio == corpCardInfo.id ? 'background: #ECF4FB;': ''" style="width: 278px;height: 68px;float:left;margin-right: 20px;margin-bottom: 10px;border: 1px solid #E4E7ED;">
                                        <div style="width: 190px;height: 15px;float: left;margin-top: 24.5px;padding-left: 10px;">
                                            <template >
                                                <el-radio v-model="corporateRadio" :label="corpCardInfo.id">
                                                    <span style="color: #747A7B;font-size: 18px;">中国{{corpCardInfo.bankName}}</span>
                                                </el-radio>
                                            </template>
                                        </div>
                                        <div style="width: 68px;height: 50px;float: left;">
                                            <img style="width: 68px;height: 50px;margin-top: 8px;" :src="corpCardInfo.bankUrl">
                                        </div>
                                    </div>
                                    <!--添加对公账户-->
                                    <div v-if="corpCardInfo == null" @click="isShowCorpCard = true" :style="addCorpCardHover == true ? 'background:#ECF4FB;':'background:#FFFFFF;'" @mouseover="addCorpCardHover = true" @mouseleave="addCorpCardHover = false" style="width: 278px;height: 68px;float:left;margin-right: 20px;margin-bottom: 10px;border: 1px solid #E4E7ED;cursor: pointer;">
                                        <div style="width: 175px;line-height: 68px;float: left;padding-left: 35px;font-size: 18px;color: #747A7B;font-weight: bold;">
                                            添加对公账户
                                        </div>
                                        <div style="width: 68px;height: 50px;float: left;">
                                            <img style="width: 50px;height: 40px;margin-top: 12px;" src="@/assets/imgs/other/jh.png">
                                        </div>
                                    </div>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </template>
                </div>
            </div>
        </div>

        <!--未查到订单信息提示-->
        <div v-if="inPayment == -2" style="width: 100%;height: 800px;">
            <div style="width: 80%;height:500px;margin: 0 auto;background: #FFFFFF;margin-top: 10px;">
                <div style="width: 100%;">
                    <div style="width: 128px;height: 128px;margin: 0 auto;padding-top:80px;">
                        <img style="width: 128px;height: 128px;" src="@/assets/imgs/other/ddcu.png">
                    </div>
                    <div style="width: 168px;height: 50px;margin: 0 auto;text-align: center;font-size:20px;font-weight:bold;padding-top:20px;">
                        {{inPaymentMsg}}
                    </div>
                </div>
                <div style="width: 350px;height: 50px;margin: 0 auto;display: flex;color: #B4B4B4;font-size: 14px;">
                    <div style="width: 100%;">
                        <div @click="a('/')" @mouseover="bankIndexHover = true" @mouseleave="bankIndexHover = false" :style="bankIndexHover == true ? 'color: #1a1a1a':''"  style="width: 50%;line-height:35px;margin: 0 auto;text-align: center;border: 1px solid #c1c1c1;border-radius: 12px;cursor: pointer;">返回首页</div>
                    </div>
                </div>
            </div>
        </div>

        <!--支付已成功提示-->
        <div v-if="inPayment == -3" style="width: 100%;height: 800px;">
            <div style="width: 80%;height:500px;margin: 0 auto;background: #FFFFFF;margin-top: 10px;">
                <div style="width: 100%;">
                    <div style="width: 128px;height: 128px;margin: 0 auto;padding-top:80px;">
                        <img style="width: 128px;height: 128px;" src="@/assets/imgs/other/ddyzf.png">
                    </div>
                    <div style="width: 168px;height: 50px;margin: 0 auto;text-align: center;font-size:20px;font-weight:bold;padding-top:20px;">
                        {{inPaymentMsg}}
                    </div>
                </div>
                <div style="width: 350px;height: 50px;margin: 0 auto;display: flex;color: #B4B4B4;font-size: 14px;">
                    <div style="width: 50%;">
                        <div @click="a('/')" @mouseover="bankIndexHover = true" @mouseleave="bankIndexHover = false" :style="bankIndexHover == true ? 'color: #1a1a1a':''"  style="width: 50%;line-height:35px;margin: 0 auto;text-align: center;border: 1px solid #c1c1c1;border-radius: 12px;cursor: pointer;">返回首页</div>
                    </div>
                    <div style="width: 50%;">
                        <div @click="a('/user/index/1')" @mouseover="orderDetailsHover = true" @mouseleave="orderDetailsHover = false" :style="orderDetailsHover == true ? 'color: #1a1a1a':''" style="width: 50%;line-height:35px;margin: 0 auto;text-align: center;border: 1px solid #c1c1c1;border-radius: 12px;cursor: pointer;">查看订单</div>
                    </div>
                </div>
            </div>
        </div>

        <!--等待倒计时-->
        <div v-if="inPayment == 2" style="width: 100%;height: 800px;">
            <div style="width: 80%;height:500px;margin: 0 auto;background: #FFFFFF;margin-top: 10px;">
                <div style="width: 100%;height: 100%;">
                    <div style="width: 128px;height: 128px;margin: 0 auto;padding-top:158px;">
                        <count-down></count-down>
                    </div>
                    <div style="width: 128px;line-height: 80px;margin: 0 auto;text-align: center;font-size:20px;font-weight:bold;">
                        {{inPaymentMsg}}
                    </div>
                    <div @click="againPay" :style="isAgainPay == true ? 'color:#FF9800' : ''" @mouseenter="isAgainPay = true" @mouseleave="isAgainPay = false" style="width: 130px;line-height: 45px;margin: 0 auto;text-align: center;font-size:20px;cursor: pointer;">重新支付</div>
                </div>
            </div>
        </div>

        <!--支付按钮-->
        <div v-if="inPayment == 0 && paramsSatus == 1 && activeName == 'corporate'" style="width: 100%;height: 60px;background: #FFFFFF;position: fixed;bottom: 0;">
            <div style="width: 450px;height: 60px;float: right;margin-right:  20px;display: flex;">
                <div  style="width:300px;line-height: 60px;font-size: 14px;text-align: right;padding-right: 10px;">
                    实付金额
                    <span v-if="user.userType == 2" style="font-size: 12px;color: #a6a6a6;">(手续费:10.00元):</span>
                    <span style="color: #FFA133;font-weight: bold;font-size: 18px;">￥ {{payParams.payAmt}}</span>
                </div>
                <div @click="toPay" style="width:150px; height: 40px;background: #FFA133;text-align: center;line-height: 40px;color: #FFFFFF;margin-top: 10px;cursor: pointer;">
                    立即支付
                </div>
            </div>
        </div>

        <!-- 添加对公账户管理 -->
        <el-dialog :show-close=false :visible.sync="isShowCorpCard" :close-on-click-modal="false">
            <addCorpCard :onClose="function (){isShowCorpCard = false}" @change="queryCorpAccount"></addCorpCard>
        </el-dialog>

        <!-- 对公支付 -->
        <organPayCode v-if="isShowOrgan == true" :sign="sign" @change="toPage($event)"></organPayCode>

    </div>
</template>

<script>

import addCorpCard from "@/components/common/Pay/organ/addCorpCard.vue";
import organPayCode from "@/components/common/Pay/organ/organPayCode.vue";
import countDown from "@/components/common/Pay/countDown.vue";
import VueQr from "vue-qr"
export default {
    components:{
        VueQr,
        addCorpCard,
        organPayCode,
        countDown,
    },
    computed:{
        user(){
            return this.$store.state.user;
        },
    },
    props:{
        //支付参数
        //支付类型1红包问答2悬赏资料3悬赏讨论4必选竞标5转账6专家咨询订单7报名事件8付费下载9专家审核订单10产品订单11服务订单12服务保证金13店铺保证金14知识仓库付费
        // payParams:{
        //     type:1,  //type = 1支付 2实时转账 3延时分账  必填
        //     memberId:"",  //商户下的用户id 唯一 必填
        //     payType:0,  //支付类型
        //     orderType:0, //订单类型
        //     payAmt:0,  //交易金额，必须大于0 必填
        //     orderNo:"",  //订单号
        // },
    },
    data() {
        return {
            inPayment:-100,  //支付状态 -4预支付订单类型错误 -3 订单已支付 -2订单信息错误 -1支付失败 0进行中 1支付成功 2等待倒计时
            inPaymentMsg:"",  //支付状态信息
            paramsSatus:1, //支付参数状态 1正常 0异常
            bankIndexHover: false,
            orderDetailsHover: false,
            activeName: 'scanCode', //scanCode
            promiseArr: [],
            dialogCustomClass: "my-dialog", // 设置自定义类名
            payParams:{},
            orderNo:'',
            productName:{},
            sign:this.$route.query.sign,
            paymentType:0, //支付方式 1扫码支付2对公支付
            isAgainPay:false,
            //机构
            corporateRadio:0,
            corpCardInfo:null,
            isShowCorpCard:false,
            addCorpCardHover: false,
            isShowOrgan:false,
            //扫码
            qrImgUrl:this.$store.state.mobileUrl,
            //定时器
            intervalId: null
        };
    },
    created() {
        var that = this;
        this.$EventBus.$EventBus.$off("payMsg")
        this.$EventBus.$on("payMsg", (data) => {
            this.$nextTick(() => {
                that.pollingQuery();
            })
        })
    },

    mounted() {
        this.queryCorpAccount();
        //获取预支付订单
        this.getPrePayOrder();

    },
    beforeDestroy() {
        clearInterval(this.intervalId); // 组件销毁时清除定时器
    },
    methods: {
        //标签页切换
        handleClick(tab) {
            var that = this;
            this.activeName = tab.name;
            //记录支付渠道
            if(tab.name === 'scanCode') {
                this.paymentType = 1;
            }else if(tab.name === 'corporate') {
                this.paymentType = 2;
            }
            that.newApi.addAndSavePrePayOrder({paymentType:that.paymentType,type:2,payParams:JSON.stringify(that.payParams),sign:that.sign}).then(() => {
                that.getPrePayOrder();
            })
        },

        //获取预支付订单
        getPrePayOrder(){
            var that = this;
            that.newApi.getPrePayOrder({sign:that.sign}).then((res)=>{
                if(res.isSuccess == 1){
                    that.payParams = res.data.payParams;
                    that.orderNo = that.payParams.orderNo;
                    that.checkPayParams();
                    that.inPayment = 0;
                    that.inPaymentMsg = "";
                }else{
                    var url = window.location.href;
                    var baseUrl = url.split('?')[0];
                    console.log(baseUrl)
                    window.opener.postMessage(res.data.payType,baseUrl);
                    that.inPayment = res.data.code;
                    that.inPaymentMsg = res.data.msg;
                }
            })
        },

        //这里进行查询是否支付成功
        pollingQuery(){
            var that = this;
            that.newApi.getPrePayOrder({sign:that.sign}).then((res)=>{
                if(res.isSuccess == -1){
                    // -2未查询到预支付订单信息 -3订单已支付
                    that.inPayment = res.data.code;
                    that.inPaymentMsg = res.data.msg;
                    if(res.data.code == -3){
                        clearInterval(that.intervalId); // 结束轮询
                        //订单支付完成跨窗口通讯
                        window.opener.postMessage(res.data.payType,'*');
                    }
                }
            })
        },

        //检查支付参数
        checkPayParams(){
            var that = this;
            if(that.payParams.type == 0){
                that.utils.err("类型错误");
                that.paramsSatus = 0;
                return false;
            }

            if(that.payParams.payAmt == "" || that.payParams.payAmt < 0.01){
                that.utils.err("金额不能低于0.01");
                that.paramsSatus = 0;
                return false;
            }

            if(that.payParams.payType == ""){
                that.utils.err("支付类型参数错误");
                that.paramsSatus = 0;
                return false;
            }

            that.getPayProductName();
        },

        //获取支付产品名
        getPayProductName() {
            var that = this;
            var params = {};
            params.type = that.payParams.type;
            params.payType = that.payParams.payType;
            that.newApi.getPayProductName(params).then((res) => {
                if(res.isSuccess == 1){
                    that.productName = res.data;
                }
            })
        },

        //查询对公账户
        queryCorpAccount(){
            var that = this;
            that.newApi.queryCorpAccount({}).then((res)=>{
                if(res.isSuccess == 1){
                    if (res.data != null){
                        that.corpCardInfo = res.data;
                        that.corporateRadio = res.data.id;
                    }
                }
            })
        },

        //跳转对公支付页面
        toPage(data){
            var that = this;
            //保存修改的预支付订单信息
            var params = that.payParams;
            that.newApi.addAndSavePrePayOrder({paymentType:2,type:3,toPay:JSON.stringify(params),sign:that.sign}).then(() => {
                this.utils.b(data.url);
            })
        },

        //对公立即支付
        toPay(){
            var that = this;
            if (that.activeName == 'corporate') {
                if(that.corpCardInfo == null){
                    that.utils.msg("请先绑定对公账户");
                    return false;
                }
                that.isShowOrgan = true;
                that.inPayment = 2;
                that.inPaymentMsg = '支付中...';
            }
        },

        //重新支付
        againPay(){
            var that = this;
            that.newApi.savePreOrderStatus({sign:that.sign}).then((res) => {
                if(res.isSuccess){
                    //刷新当前页面
                    window.location.reload();
                }
            })
        }
    }
};
</script>

<style scoped>
.full-screen-div {
    width: 100%;
    height: auto;
    color: #333333
}
table {
    width: 100%;
    table-layout: fixed; /* 固定表格布局 */
    border-collapse: collapse; /* 合并边框 */
    border: 1px solid #D7D8D9; /* 设置表格边框 */
    font-size: 12px;
    text-indent: 12px;
}
table td {
    width: 20%; /* 平均分配每一列宽度 */
    height: 40px; /* 设置每一行的高度为40px */
}
/* 自定义对话框样式 */
/deep/.my-dialog .el-dialog__header {
    padding: 0px;
}

/deep/.my-dialog .el-dialog__body {
    padding: 10px;
}
</style>
